<template>
    <div>
        <div class="success">
            <Header backType="blank">
                <span slot="headText">操作成功</span>
            </Header>
            <div>
                <Svgs name="iconsuccess1" color="#4AC672" classes="icon-success"></Svgs>
                <div class="successTitle">操作成功</div>
                <div class="successTxt">
                    <span>*</span>
                    如遇支付问题,请致电
                    <span @click="ihtml.telCall('01056304858')" v-html="phoneText"></span>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { phoneList } from '@/config/phone/index.js'
export default {
    data() {
        return {
            mealItemDetail: {}
        }
    },
    computed: {
        ...mapGetters([
            "event"
        ]),
        phoneText: {
            get: function() {
                let tenant = this.event.tenant_code || 'pfizer';
                return phoneList[tenant];  
            }    
        }
    },
    created() {
    },
    methods: {
       
    }
};
</script>
<style lang="scss">
@import "success.scss";
</style>
